<template>
  <div class="initiation">
    <div class="initiation-top" v-show="showTop">
      <div></div>
      <div class="initiation-title">入会申请</div>
      <div>
        <el-button type="primary" @click="submitForm('form')" v-if="!disabled"
          >提交</el-button
        >
      </div>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
      class="demo-ruleForm"
    >
      <!-- 企业信息 -->
      <el-row>
        <el-form-item>
          <div class="form-title">
            <img
              src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/initiation_company.png"
              alt=""
            />
            <span>企业信息</span>
          </div>
        </el-form-item>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="公司全称:" prop="company_name">
              <el-input
                placeholder="输入公司全称"
                disabled
                v-model="form.company_name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="海关注册十位数编码:">
              <el-input
                placeholder="输入海关注册十位数编码"
                :disabled="disabled"
                v-model="form.customs_code"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业类型:" prop="company_type">
              <el-select
                v-model="form.company_type"
                :disabled="disabled"
                class="input-width-all"
                placeholder="选择企业类型"
              >
                <el-option
                  v-for="item in companyTypeList"
                  :key="item.value"
                  :label="item.label_zh"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业性质:">
              <el-select
                v-model="form.company_nature"
                :disabled="disabled"
                class="input-width-all"
                placeholder="选择企业性质"
              >
                <el-option
                  v-for="item in companyNatureList"
                  :key="item.value"
                  :label="item.label_zh"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册资金:">
              <el-input
                placeholder="输入注册资金"
                :disabled="disabled"
                v-model="form.registered_capital"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业传真:">
              <el-input
                placeholder="输入企业传真"
                :disabled="disabled"
                v-model="form.company_fax"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业邮箱:" prop="company_email">
              <el-input
                placeholder="输入企业邮箱"
                :disabled="disabled"
                v-model="form.company_email"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="注册海关:">
              <el-input
                placeholder="输入注册海关"
                :disabled="disabled"
                v-model="form.customs_regist"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="办公地址:" prop="company_address">
              <el-input
                placeholder="输入办公地址"
                :disabled="disabled"
                v-model="form.company_address"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮编:">
              <el-input
                placeholder="输入邮编"
                :disabled="disabled"
                v-model="form.zip_code"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报关注册登记日期:">
              <el-date-picker
                v-model="form.declaration_registration_date"
                :disabled="disabled"
                type="date"
                class="input-width-all"
                placeholder="选择报关注册登记日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="报关方式:">
              <el-select
                v-model="form.customs_clearance_way"
                :disabled="disabled"
                class="input-width-all"
                placeholder="选择报关方式"
              >
                <el-option
                  v-for="item in customsClearanceWayList"
                  :key="item.value"
                  :label="item.label_zh"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>

      <!-- 人事信息 -->
      <el-row class="form-piece-row">
        <el-form-item>
          <div class="form-row">
            <div class="form-title">
              <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/initiation_people.png"
                alt=""
              />
              <span>人事信息</span>
            </div>
            <div class="row-line"></div>
          </div>
        </el-form-item>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="法人代表:">
              <el-input
                placeholder="输入法人代表"
                :disabled="disabled"
                v-model="form.legal_name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话:">
              <el-input
                placeholder="输入电话"
                :disabled="disabled"
                v-model="form.legal_telephone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机:" prop="legal_mobile">
              <el-input
                placeholder="输入手机"
                :disabled="disabled"
                v-model="form.legal_mobile"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="总经理或负责人:">
              <el-input
                placeholder="输入总经理或负责人"
                :disabled="disabled"
                v-model="form.charge_name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话:">
              <el-input
                placeholder="输入电话"
                :disabled="disabled"
                v-model="form.charge_telephone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机:" prop="charge_mobile">
              <el-input
                placeholder="输入手机"
                :disabled="disabled"
                v-model="form.charge_mobile"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="协会联系人:" prop="contact_name">
              <el-input
                placeholder="输入协会联系人"
                :disabled="disabled"
                v-model="form.contact_name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="电话:" prop="contact_telephone">
              <el-input
                placeholder="输入电话"
                :disabled="disabled"
                v-model="form.contact_telephone"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机:" prop="contact_mobile">
              <el-input
                placeholder="输入手机"
                :disabled="disabled"
                v-model="form.contact_mobile"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="企业规模:">
              <el-select
                v-model="form.company_scale"
                :disabled="disabled"
                class="input-width-all"
                placeholder="选择企业规模"
              >
                <el-option
                  v-for="item in companyScaleList"
                  :key="item.value"
                  :label="item.label_zh"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="企业员工人数:">
              <el-select
                v-model="form.employee_num"
                :disabled="disabled"
                class="input-width-all"
                placeholder="选择企业员工人数"
              >
                <el-option
                  v-for="item in staffsizeList"
                  :key="item.value"
                  :label="item.label_zh"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>

      <!-- 业务信息-->
      <el-row class="form-piece-row">
        <el-form-item>
          <div class="form-row">
            <div class="form-title">
              <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/initiation_business.png"
                alt=""
              />
              <span>业务信息</span>
            </div>
            <div class="row-line"></div>
          </div>
        </el-form-item>
        <el-row>
          <el-form-item label="经营范围:" prop="business_scope">
            <el-input
              placeholder="输入经营范围"
              :disabled="disabled"
              v-model="form.business_scope"
            ></el-input>
          </el-form-item>
          <el-form-item label="特色业务:" prop="characteristics_business">
            <el-select
              v-model="form.characteristics_business"
              class="input-width-all"
              :disabled="disabled"
              multiple
              placeholder="请选择特色业务"
            >
              <el-option
                v-for="item in characteristicsBusinessList"
                :key="item.value"
                :label="item.label_zh"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="其他:"
            v-show="
              form.characteristics_business &&
              form.characteristics_business.includes(0)
            "
          >
            <el-input
              placeholder="输入特色业务"
              :disabled="disabled"
              v-model="form.business_other"
            ></el-input>
          </el-form-item>
          <el-form-item label="企业优势:">
            <el-select
              v-model="form.company_advantage"
              class="input-width-all"
              :disabled="disabled"
              multiple
              placeholder="请选择企业优势"
            >
              <el-option
                v-for="item in companyAdvantageList"
                :key="item.value"
                :label="item.label_zh"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="其他:"
            v-show="
              form.company_advantage && form.company_advantage.includes(0)
            "
          >
            <el-input
              placeholder="输入企业优势"
              :disabled="disabled"
              v-model="form.advantage_other"
            ></el-input>
          </el-form-item>
          <el-form-item label="期望协会提供的服务内容:" prop="expect_service">
            <el-select
              v-model="form.expect_service"
              class="input-width-all"
              :disabled="disabled"
              multiple
              placeholder="请选择期望协会提供的服务内容"
            >
              <el-option
                v-for="item in expectServiceList"
                :key="item.value"
                :label="item.label_zh"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="其他:"
            v-show="form.expect_service && form.expect_service.includes(0)"
          >
            <el-input
              placeholder="输入期望协会提供的服务内容"
              :disabled="disabled"
              v-model="form.service_other"
            ></el-input>
          </el-form-item>
        </el-row>
      </el-row>

      <!-- 党组信息 -->
      <el-row class="form-piece-row">
        <el-form-item>
          <div class="form-row">
            <div class="form-title">
              <img
                src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/initiation_communist.png"
                alt=""
              />
              <span>党组信息</span>
            </div>
            <div class="row-line"></div>
          </div>
        </el-form-item>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item>
              <span class="radio-title">是否有党组织:</span>
              <el-radio-group v-model="form.has_porganization">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item>
              <span class="radio-title">是否有党员:</span>
              <el-radio-group v-model="form.has_pmember">
                <el-radio label="1">是</el-radio>
                <el-radio label="0">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="党组织名称:">
              <el-input
                placeholder="输入党组织名称"
                :disabled="disabled"
                v-model="form.porganization_name"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="党组织组建时间:">
              <el-date-picker
                v-model="form.porganization_time"
                type="date"
                :disabled="disabled"
                class="input-width-all"
                placeholder="选择党组织组建时间"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="党组织类型:">
              <el-select
                v-model="form.porganization_type"
                :disabled="disabled"
                class="input-width-all"
                placeholder="选择党组织类型"
              >
                <el-option
                  v-for="item in porganizationTypeList"
                  :key="item.value"
                  :label="item.label_zh"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="党组织性质:">
              <el-select
                v-model="form.porganization_nature"
                :disabled="disabled"
                class="input-width-all"
                placeholder="选择党组织性质"
              >
                <el-option
                  v-for="item in porganizationNatureList"
                  :key="item.value"
                  :label="item.label_zh"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="党组织书记:">
              <el-input
                placeholder="输入党组织书记"
                :disabled="disabled"
                v-model="form.porganization_secretary"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="手机:" prop="secretary_mobile">
              <el-input
                placeholder="输入手机"
                :disabled="disabled"
                v-model="form.secretary_mobile"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号:" prop="secretary_id_no">
              <el-input
                placeholder="输入身份证号"
                :disabled="disabled"
                v-model="form.secretary_id_no"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="党员人数:">
              <el-input
                placeholder="输入党员人数"
                :disabled="disabled"
                v-model="form.num_pmember"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="预备党员人数:">
              <el-input
                placeholder="输入预备党员人数"
                :disabled="disabled"
                v-model="form.num_pre_pmember"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="入党积极分子人数:">
              <el-input
                placeholder="输入入党积极分子人数"
                :disabled="disabled"
                v-model="form.num_activists"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="党组织情况:">
              <el-input
                placeholder="输入党组织情况"
                :disabled="disabled"
                v-model="form.porganization_situation"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-row>
    </el-form>
    <el-button style="display: none" type="primary" @click="reset"
      >重置</el-button
    >
  </div>
</template>

<script>
import { validateTelephone, validateIdCard } from "~/baseUtils/validate";
import {
  staffsizeList,
  companyTypeList,
  companyNatureList,
  customsClearanceWayList,
  companyScaleList,
  porganizationTypeList,
  porganizationNatureList,
  characteristicsBusinessList,
  companyAdvantageList,
  expectServiceList,
} from "~tcb/utils/enumValue";
export default {
  name: "initiation",
  props: {
    form_data: {
      //回显数据
      type: Object,
      default: {},
    },
    disabled: {
      //表单输入选择禁用
      type: Boolean,
      default: false,
    },
    showTop: {
      //头部按钮显示
      type: Boolean,
      default: true,
    },
  },
  components: {},
  data() {
    return {
      form: {}, //表单参数
      rules: {
        company_name: [
          { required: true, message: "请输入公司全称", trigger: "blur" },
        ],
        company_type: [
          { required: true, message: "请选择企业类型", trigger: "blur" },
        ],
        company_email: [
          { required: true, message: "请输入企业邮箱", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: "blur",
          },
        ],
        company_address: [
          { required: true, message: "请输入办公地址", trigger: "blur" },
        ],
        contact_name: [
          { required: true, message: "请输入协会联系人", trigger: "blur" },
        ],
        contact_telephone: [
          { required: true, message: "请输入协会联系人电话", trigger: "blur" },
        ],
        contact_mobile: [
          {
            required: true,
            validator: async (rule, value, callback) => {
              if (value == "") {
                callback("请输入协会联系人手机号");
              } else if (!validateTelephone(value)) {
                callback("手机号格式错误");
              } else {
              }
            },
            trigger: "blur",
          },
        ],
        business_scope: [
          { required: true, message: "请输入经营范围", trigger: "blur" },
        ],
        characteristics_business: [
          { required: true, message: "请选择特色业务", trigger: "blur" },
        ],
        expect_service: [
          {
            required: true,
            message: "请选择期望协会提供的服务内容",
            trigger: "blur",
          },
        ],
        legal_mobile: [
          {
            required: false,
            validator: async (rule, value, callback) => {
              if (value == "") {
              } else if (!validateTelephone(value)) {
                callback("手机号格式错误");
              } else {
              }
            },
            trigger: "blur",
          },
        ],
        charge_mobile: [
          {
            required: false,
            validator: async (rule, value, callback) => {
              if (value == "") {
              } else if (!validateTelephone(value)) {
                callback("手机号格式错误");
              } else {
              }
            },
            trigger: "blur",
          },
        ],
        secretary_mobile: [
          {
            required: false,
            validator: async (rule, value, callback) => {
              if (value == "") {
              } else if (!validateTelephone(value)) {
                callback("手机号格式错误");
              } else {
              }
            },
            trigger: "blur",
          },
        ],
        secretary_id_no: [
          {
            required: false,
            validator: async (rule, value, callback) => {
              if (value == "") {
              } else if (!validateIdCard(value)) {
                callback("身份证号格式错误");
              } else {
              }
            },
            trigger: "blur",
          },
        ],
      }, //表单验证
      staffsizeList, //企业员工人数
      companyTypeList, //企业类型
      companyNatureList, //企业性质
      customsClearanceWayList, //报关方式
      companyScaleList, //企业规模
      porganizationTypeList, //党组织类型
      porganizationNatureList, //党组织性质
      characteristicsBusinessList, //特色业务
      companyAdvantageList, //企业优势
      expectServiceList, //期望服务
    };
  },
  watch: {
    "form.characteristics_business"(newV) {
      if (!newV.includes(0)) {
        this.form.business_other = "";
      }
    },
    "form.company_advantage"(newV) {
      if (!newV.includes(0)) {
        this.form.advantage_other = "";
      }
    },
    "form.expect_service"(newV) {
      if (!newV.includes(0)) {
        this.form.service_other = "";
      }
    }
  },
  mounted() {
    this.form = {
      source: this.PJSource,
      company_id: this.USER_INFO.company_id,
      user_id: this.USER_INFO.id,
      company_name:
        this.USER_INFO.company_name_zh || this.USER_INFO.company_name_en,
      customs_code: "",
      company_type: "",
      registered_capital: "",
      company_nature: "",
      company_fax: "",
      company_email: "",
      company_address: "",
      customs_regist: "",
      zip_code: "",
      legal_name: "",
      legal_telephone: "",
      legal_mobile: "",
      charge_name: "",
      charge_telephone: "",
      charge_mobile: "",
      contact_name: "",
      contact_telephone: "",
      contact_mobile: "",
      declaration_registration_date: "",
      customs_clearance_way: "",
      business_scope: "",
      company_scale: "",
      employee_num: "",
      characteristics_business: [],
      business_other: "",
      company_advantage: [],
      advantage_other: "",
      expect_service: [],
      service_other: "",
      has_porganization: "0",
      porganization_name: "",
      porganization_time: "",
      porganization_type: "",
      porganization_nature: "",
      porganization_secretary: "",
      secretary_mobile: "",
      secretary_id_no: "",
      porganization_situation: "",
      has_pmember: "0",
      num_pmember: "",
      num_pre_pmember: "",
      num_activists: "",
    };
    if (this.form_data.id) {
      this.dataEcho();
    }
  },
  methods: {
    dataEcho() {
      let {
        customs_code,
        company_type,
        registered_capital,
        company_nature,
        company_fax,
        company_email,
        company_address,
        customs_regist,
        zip_code,
        legal_name,
        legal_telephone,
        legal_mobile,
        charge_name,
        charge_telephone,
        charge_mobile,
        contact_name,
        contact_telephone,
        contact_mobile,
        declaration_registration_date,
        customs_clearance_way,
        business_scope,
        company_scale,
        employee_num,
        characteristics_business,
        business_other,
        company_advantage,
        advantage_other,
        expect_service,
        service_other,
        has_porganization,
        porganization_name,
        porganization_time,
        porganization_type,
        porganization_nature,
        porganization_secretary,
        secretary_mobile,
        secretary_id_no,
        porganization_situation,
        has_pmember,
        num_pmember,
        num_pre_pmember,
        num_activists,
      } = this.form_data;
      this.form = {
        source: this.PJSource,
        company_id: this.USER_INFO.company_id,
        user_id: this.USER_INFO.id,
        company_name:
          this.USER_INFO.company_name_zh || this.USER_INFO.company_name_en,
        customs_code,
        company_type,
        registered_capital,
        company_nature: company_nature ? company_nature : "",
        company_fax,
        company_email,
        company_address,
        customs_regist,
        zip_code,
        legal_name,
        legal_telephone,
        legal_mobile,
        charge_name,
        charge_telephone,
        charge_mobile,
        contact_name,
        contact_telephone,
        contact_mobile,
        declaration_registration_date: declaration_registration_date ? this.$moment(declaration_registration_date * 1000).format("YYYY-MM-DD") : "",
        customs_clearance_way: customs_clearance_way ? customs_clearance_way : "",
        business_scope,
        company_scale: company_scale ? company_scale : "",
        employee_num: employee_num ? employee_num : "",
        characteristics_business: this.toNumberArr(characteristics_business),
        business_other,
        company_advantage: this.toNumberArr(company_advantage),
        advantage_other,
        expect_service: this.toNumberArr(expect_service),
        service_other,
        has_porganization: `${has_porganization}`,
        porganization_name,
        porganization_time: porganization_time ? this.$moment(porganization_time * 1000).format("YYYY-MM-DD") : "",
        porganization_type: porganization_type ? porganization_type : "",
        porganization_nature: porganization_nature ? porganization_nature : "",
        porganization_secretary,
        secretary_mobile,
        secretary_id_no,
        porganization_situation,
        has_pmember: `${has_pmember}`,
        num_pmember: num_pmember ? num_pmember : "",
        num_pre_pmember: num_pre_pmember ? num_pre_pmember : "",
        num_activists: num_activists ? num_activists : "",
      };
    },
    toNumberArr(value) {
      let arr = [];
      value && value.split(",").forEach((item) => {
        (Number(item) || Number(item) === 0) && arr.push(Number(item));
      });
      return arr;
    },
    // 提交
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("submitForm", this.form);
        } else {
          this.$message.warning("请检查输入项");
          return false;
        }
      });
    },
    // 重置
    reset() {
      let _params = Object.assign({}, this.form);
      Object.keys(_params).forEach((item) => {
        _params[item] = null;
      });
      this.form = _params;
    },
  },
};
</script>

<style scoped lang="less">
.initiation {
  width: 1120px;
  background: #ffffff;
  /deep/.el-form-item__label,
  .radio-title {
    font-size: 16px;
    color: #606266;
  }
  /deep/.el-input__inner {
    font-size: 16px;
  }
  /deep/.el-textarea__inner {
    font-size: 16px;
  }
  /deep/.el-form-item__label {
    padding: 0;
  }
  /deep/.el-form-item {
    margin-bottom: 12px;
  }
  .initiation-top {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 48px;
  }
  .initiation-title {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
  }
  .form-title {
    span {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
      vertical-align: middle;
      margin-left: 12px;
    }
  }
  .form-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .row-line {
      width: 1012px;
      height: 0;
      border-bottom: 1px dashed #dddddd;
    }
  }
  .radio-title {
    margin-right: 24px;
  }
  .form-piece-row {
    margin-top: 22px;
  }
  .input-width-all {
    width: 100%;
  }
}
</style>
